.homeworkReorderingTable {
  .MuiTableCell-root {
    border: none !important;
    border-bottom: solid 1px #7d7d7d9e !important;
    padding: 10px;
  }
  .tableRow {
    margin: 10px;
  }
  .index-box {
    border: 2px solid black;
    border-radius: 12px;
    padding: 1vw;
    max-width: 3vw;
    max-height: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .drag-row {
    cursor: grabbing;
  }
}
