.single-select-component {
  cursor: move;

  img {
    width: 5vw;
  }

  .single-select {
    font-size: 0.9vw;
    font-weight: 600;
  }
}
