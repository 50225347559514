.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.385);
  z-index: 10000;

  .modal {
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 0.5vw;
    width: 30vw;
    position: relative;


    .grade-package{
      font-size: 1vw;
      font-weight: 500;
      align-items: center;
      gap: 1vw;
      margin-top: 1vw;
      .package-name{
        font-weight: 600;
        margin-right: 0.5vw;
      }
      .package{
        color:#b25feb;
      }
      .grade-name{
        font-weight: 600;
        margin-right: 0.5vw;
      
      }
      .grade{
        color:#b25feb;
      }
      // margin-left: 1vw;
    }

    .close-icon-div {
      position: absolute;
      top: -0.85vw;
      right: -0.85vw;
      width: 1.75vw;
      height: 1.75vw;
      border-radius: 50%;
      background-color: white;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.14);

      .close-icon {
        width: 45%;
        color: #2e3a59;
      }

    }

    .add-module-header {
      width: 100%;
      padding: 0.75vw 1.5vw;
      border-bottom: 1px solid #ccc;

      .modal-title {
        font-size: 1.25vw;
        font-weight: 600;
      }
    
    }

    .add-module-form {
      width: 100%;
      padding: 1vw 1.5vw;
      border-bottom: 1px solid #ccc;
      gap: 1vw;

      .add-module-form-title {
        font-size: 1vw;
        font-weight: 600;
        margin-bottom: 0.4vw;
      }

      .text-box {
        font-size: 1.2vw;
        font-weight: 500;
        padding: 0.8vw;
        width: 100%;
        border-radius: 8px;

        &:focus {
          outline: none;
        }

        &::placeholder {
          font-size: 1vw;
          color: #adb5bd;
          font-weight: 600;
        }
      }

      .add-module-button {
        align-self: flex-start;
        min-width: 5vw;
        border: none;
        cursor: pointer;
        background-color: #b25feb;
        padding: 0.5vw 1vw;
        border-radius: 0.5vw;
        font-size: 0.8vw;
        font-weight: 700;
        color: white;

        input {
          display: none;
        }

        &:hover {
          background-color: #a948ea;
        }

        &:disabled {
          cursor: unset;
          background-color: #ccc;
        }
      }

      .img-uploaded {
        font-size: 1vw;
        font-weight: 500;
        display: flex;
        align-self: flex-start;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        position: relative;
        border: 1px dashed grey;
        border-radius: 8px;

        .close-icon-div {
          top: -0.6vw;
          right: -0.6vw;
          width: 1.2vw;
          height: 1.2vw;
        }

        .module-uploaded-img {
          height: 4vw;
          width: 4vw;
          border-radius: 8px;
          padding: 0.15vw;
        }
      }

      .api-error {
        color: red;
        font-weight: 500;
        font-size: 1vw;
        text-align: center;
      }
    }

    .add-module-action {
      width: 100%;
      justify-content: center;
      padding: 1vw 0;

      .action-btn {
        background-color: #b25feb;
        padding: 0.4vw 1.5vw;
        border-radius: 0.5vw;
        color: white;
        font-weight: 600;

        &:hover {
          background-color: #a845eb;
        }

        &:disabled {
          background-color: #ccc;
        }

        .MuiButton-label {
          font-size: 1vw;
        }
      }
    }
  }
}
