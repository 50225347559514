.background-component {
  align-items: center;
  // cursor: move;
  gap: 0.2vw;
  margin-bottom: 1vw;

  .img-upload {
    width: 1vw;
    height: 1vw;
    margin-left: 0.5vw;
  }

  .text-subtitle {
    text-decoration: underline;
    color: #343A40;
    font-weight: 500;
    margin-right: 10px;
    font-size: 1.1vw;
  }
  .color-div {
    height: 1vw;
    width: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1vw;
  }
  .upload-icon {
    width: 1.4vw;
    height: 1.4vw;
  }
  .check-icon {
    width: 0.9vw;
    height: 0.9vw;
    color: #2e3a59;
    align-self: center;
  }
}
