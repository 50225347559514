.video-placeholder {
  width: 100%;
  height: 200px;
  margin-bottom: 16px;
  background: black;
  border: 2px solid #b25fea;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .play-btn {
    width: 60px;
    height: 60px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}
