.media-box-container {
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5vw;
  gap: 8px;
  width: 100%;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='grey' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");

  &.selected {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='orange' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  &.error {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='red' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  .loader {
    color: black !important;
  }

  .loader-subtitle {
    font-weight: 500;
  }

  .media-box-image {
    max-width: 100%;
    max-height: 100%;
  }

  .image-logo {
    margin-top: 1.5vw;
    width: 40%;
  }

  .input-image-label {
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.75vw;
    font-weight: 700;
  }
  .input-video-label {
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.75vw;
    font-weight: 700;
    padding-bottom: 1vh;
  }
  .input-gif-label {
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.75vw;
    font-weight: 700;
    padding-bottom: 1vh;
  }

  #input-image {
    display: none;
  }
  #input-video {
    display: none;
  }
  #input-gif {
    display: none;
  }

  .close-icon-div {
    position: absolute;
    top: -0.7vw;
    right: -0.7vw;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #e9ecef;
    cursor: pointer;

    .close-icon {
      width: 55%;
      color: #2e3a59;
    }
  }
  .text-box {
    width: 0;
    height: 0;
    position: absolute;
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #ccc;
      font-weight: 500;
      text-align: center;
    }
  }
  .or-text {
    color: #ccc;
    font-size: 1vw;
    font-weight: 600;
  }
  .paste-img-text {
    color: #ccc;
    font-size: 1vw;
    font-weight: 700;
  }
}
