.flash-cards-intro {
  height: 100%;
  gap: 1vw;
  position: relative;

  * {
    font-family: 'Montserrat', sans-serif !important;
  }

  .package-parent-row {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #210e42;
    border-radius: 8px;
    padding: 0.5vw;
    gap: 1vw;

    .package-name-box {
      justify-content: center;
      align-items: center;
      font-size: 1vw;
      height: 2.5vw;
      color: #210e42;
      font-weight: 600;
      border-radius: 0.5vw;
      cursor: pointer;
      flex: 1;

      &:hover {
        background-color: rgb(240, 240, 240);
      }

      &.selected {
        background-color: #210e42;
        color: white;
      }
    }
  }

  .grades-row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1vw;

    .grades-list {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: auto;
      gap: 1vw;

      &::-webkit-scrollbar {
        display: none;
      }

      .grade-box {
        align-items: center;
        padding: 0 1vw;
        font-size: 1vw;
        font-weight: 600;
        height: 2.5vw;
        border: 2px solid #b25feb;
        color: #b25feb;
        border-radius: 8px;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          background-color: #f7ebff;
        }

        &.selected {
          background-color: #b25feb;
          color: white;
        }
      }
    }

    .add-module-button {
      border: none;
      font-size: 1vw;
      height: 100%;
      padding: 0 1vw;
      background-color: #b25feb;
      color: #fff;
      font-weight: 600;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: #a844eb;
      }
    }
  }

  .flash-cards-table-container {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 0.5vw;
    height: calc(100% - 8vw);

    .flash-cards-table {
      width: 100%;

      .MuiTableCell-root {
        border: unset !important;
        border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
        padding: 0.5vw;
      }

      .MuiTableRow-hover {
        &:hover {
          background-color: #f4f4f4;
        }
      }

      .table-head-cell {
        font-size: 1.1vw;
        font-weight: 600;
        background-color: #efefef;
        white-space: nowrap;

        &.name {
          width: 30%;
        }

        &.description {
          width: 30%;
        }
      }

      .table-row {
        cursor: pointer;

        .table-body-cell {
          font-size: 1vw;
          font-weight: 500;
        }

        .chevron-right {
          height: 2vw;
          width: 1.8vw;
          color: #a844eb;
        }

        .title {
          font-size: 1.2vw;
          font-weight: 500;
          white-space: nowrap;

          img {
            border-radius: 50%;
            margin-right: 1vw;
            width: 2.5vw;
            height: 2.5vw;
          }
        }

        .status {
          width: min-content;
          padding: 0.25vw 0.75vw;
          border-radius: 0.35vw;
          white-space: nowrap;
          color: white;
          margin: auto;

          &.live {
            background-color: #00b200;
          }

          &.not-live {
            background-color: orange;
          }
        }

        .row-action {
          gap: 1vw;
          justify-content: center;

          button {
            border: none;
            justify-content: center;
            align-items: center;
            background-color: #b25feb;
            color: white;
            cursor: pointer;

            &:hover {
              background-color: #a642e8;
            }

            &.preview {
              border-radius: 0.3vw;
              font-size: 1vw;
              font-weight: 600;
              padding: 0.25vw 0.5vw;
            }

            &.circular-button {
              padding: 0.4vw;
              border-radius: 50%;

              &.delete {
                background-color: rgb(245, 112, 112);
              }

              svg {
                display: block;
                font-size: 1vw;
              }
            }
          }
        }
      }
    }
  }
}
