.text-parent-div {
  width: 100%;
  position: relative;
  border: 1.5px dashed #ccc;

  &.selected {
    border-color: #F49F3F;
  }

  &.error {
    border-color: red;
  }

  .text-box {
    padding: 0.8vw;
    width: 100%;
    border: none;
    resize: none;
    display: block;
    background-color: transparent;
  
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #adb5bd;
      font-weight: 600;
    }
  
    &.heading-1 {
      font-size: 18px;
      font-weight: 700;
    }
  
    &.heading-2 {
      font-size: 16px;
      font-weight: 700;
    }
  
    &.text {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .close-icon-div {
    position: absolute;
    top: -0.7vw;
    right: -0.7vw;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #E9ECEF;
    cursor: pointer;

    .close-icon {
      width: 52%;
      color: #2E3A59;
    }
  }
}
