.group-misfit-outer-container {
  display: flex;
  flex-direction: column;
  gap: 1vw;

  .group-misfit-header {
    font-size: 1.5vw;
    font-weight: 600;
  }
  .group-misfit-center {
    display: flex;
    align-self: center;
    height: fit-content;
    align-items: center;
    min-height: 50vh;
  }
}
.width-95 {
  width: 95vw;
}
.group-misfit-inner-container {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  min-width: 65vw;

  .group-table {
    border: solid 2px #3a3a3a;

    .no-bottom-border {
      border-bottom: 1px solid transparent !important;
    }
    .vertical-align-initial {
      vertical-align: initial;
    }
    .group-details-container {
      display: flex;
      flex-direction: column;
      gap: 0.5vw;
      align-items: center;
    }
  }
}
