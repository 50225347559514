.error-loader-box {
  position: absolute;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
  font-weight: 600;
  gap: 1vw;
  color: #b25feb;

  div {
    color: #b25feb;
  }

  .api-error {
    color: red;
    font-weight: 500;
    font-size: 1.5vw;
    text-align: center;
  }
}