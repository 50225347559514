.editRoleContainer{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .MuiInput-root{
            min-width: 15vw;
        }
    }
}
.adminRoleContainer{
    display: flex;
    flex-direction: column;
    min-height: 70vh;
    gap: 1vw;

.headerContainer{
    display: flex;
    justify-content: space-between;
    .header{
        font-size: 1.5vw;
        font-weight: 600;
    }
}

.tableContainer{
	.MuiTableHead-root{
        background-color: #e0e0e0;
        font-size: 1.25vw;
        font-weight: 600;
        text-align: left;   
        height: 4.5vw;                  
    }    
    .MuiTableCell-root{ 
        text-align: inherit;       
        border: 1px solid #e0e0e0;
        padding: 6px 24px 6px 16px;
        height: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
    .MuiTableBody-root{
        font-size: 1vw;
        font-weight: 500;
        height: 4vw;       
    }   
}
.MuiCircularProgress-root{
    margin: auto;
}
}
