.teacherTrainerContainer {
  display: flex;
  flex-direction: column;
  gap: 0.8vw;
  min-height: 70vh;

  .selectPackageDropdown {
    width: 10vw !important;
  }
  .teacherTrainerHeaderContainer {
    display: flex;
    justify-content: space-between;
    .teacherTrainerHeaderInnerContainer {
      display: flex;
      gap: 1vw;
      align-items: center;
      .MuiIcon-root {
        cursor: pointer;
      }
      .teacherTrainerHeader {
        font-size: 1.5vw;
        font-weight: 600;
      }
    }
  }
  .tableContainer {
    overflow-x: auto;

    .headerSortIcon {
      font-size: 1.25vw !important;
      cursor: pointer !important;
      vertical-align: middle !important;
      margin-right: 5px !important;
    }

    .headers {
      display: flex;
      align-items: center;
      .sortIcon {
        font-size: 1.25vw !important;
        cursor: pointer !important;
        vertical-align: middle !important;
        margin-right: 5px !important;
      }
    }
    .MuiTableHead-root {
      position: sticky;
      z-index: 1;
      top: 0;
      background-color: #e0e0e0;
      font-size: 1vw;
      font-weight: 600;
      height: 4.5vw;
    }
    .MuiTableCell-root {
      text-align: center;
      border: 1px solid #e0e0e0;
      padding: 6px 24px 6px 16px;
      height: inherit;
      font-size: inherit;
      font-weight: inherit;
      &.MuiTableCell-head {
        &.MuiTableCell-stickyHeader {
          background-color: #e0e0e0 !important;
        }
      }
    }
    .clickableCell {
      padding: 6px;
      cursor: pointer;
      .clickableDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .bold {
      font-weight: 600;
      font-size: 0.95vw;
      background-color: #f1f0f0;
    }
    .MuiTableBody-root {
      font-size: 1vw;
      font-weight: 500;
      height: 4vw;
    }

    .MuiCircularProgress-root {
      margin: auto;
    }
  }

  .teacherTrainerPagination {
    padding-bottom: 2vw !important;
    overflow: hidden !important;
  }
  .MuiTablePagination-root:last-child {
    width: fit-content;
  }
  .centerAligned {
    margin: auto;
  }
}
