.MuiTableHead-root {
  background-color: #e0e0e0;
  font-size: 1.25vw;
  font-weight: 600;
  height: 4.5vw;
}
.MuiTableCell-root {
  text-align: center;
  border: 1px solid #e0e0e0;
  padding: 6px 24px 6px 16px;
  height: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.bold{
    font-weight: 600;
    font-size: 0.95vw;
    background-color: #f1f0f0;
}
.MuiTableBody-root {
  font-size: 1vw;
  font-weight: 500;
  height: 4vw;
}

.MuiCircularProgress-root {
  margin: auto;
}
