.media-box {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='black' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  min-width: 12vw;
  align-items: center;
  justify-content: center;
  font-size: 0.8vw;
  font-weight: 700;
  padding: 0.8vw;
  gap: 0.25vw;
  color: #343a40;
  cursor: move;
  gap: 0.5vw;
  .subtitle {
    font-weight: 600;
  }

  img {
    width: 2vw;
  }
}
