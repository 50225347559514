.main-div-button {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 10;
  left: -100%;
  padding: 1.2vw 0.8vw 1.2vw 0.8vw;
  background: #fff;
  border-radius: 12px;
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.72);
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.12);

  .color-button-div {
    padding: 1px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .colors {
      width: 2vw;
      height: 2vw;
      cursor: pointer;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;

      &.white {
        color: white;
        background-color: #8e8e8e;
        border: 1px dotted silver;
      }

      &.non-white {
        color: black;
      }
    }
  }
  .radio-div {
    margin-top: 2vh;
    .MuiFormControlLabel-label {
      font-size: 1vw !important;
      font-weight: 600;
    }
  }
}
