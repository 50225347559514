.dropzone-container {
  flex: 1;
  border: 6px solid black;
  width: 100%;
  border-radius: 2vw;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.22);
  -ms-transform: scaleY(1); /* IE 9 */
  transform: scaleY(1);
  height: calc(100% - 5.8vw);

  .dropzone-box {
    overflow: auto;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;
    padding: 1.5vw;

    &::-webkit-scrollbar {
      display: none;
    }

    .no-data-ui {
      flex: 1;
      justify-content: center;
      align-items: center;
      gap: 1vw;
      padding: 0vw 2vh 0vw 2vh;

      img {
        width: 6vw;
      }

      p {
        font-size: 1vw;
        font-weight: 700;
        text-align: center;
      }
    }
  }
}
