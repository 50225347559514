.main-div-text {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 10;
  left: -100%;
  padding: 1.2vw 0.8vw 1.2vw 0.8vw;
  background: #fff;
  border-radius: 12px;
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.72);
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.12);

  .color-text-div {
    padding: 1px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .colors {
      width: 2vw;
      height: 2vw;
      cursor: pointer;
      border-radius: 6px;
      // margin-right: 0.1vw;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1vw;
      font-weight: 600;

      &.white {
        color: black;
        border: 1px dotted silver;
      }

      &.non-white {
        color: white;
      }
    }
  }
  .form-control {
    width: 5vw;
    margin-top: 15px;

    .MuiInputBase-formControl {
      border: #e9ecef !important;
    }
  }
  .select {
    height: 2vw;
    font-size: 0.9vw;
    font-weight: bold;
  }
  .align-row {
    margin-top: 15px;
  }

  .align-img {
    width: 2vw;
    height: 2vw;
  }
}
