.row-flex {
    display: flex;
    flex-direction: row;
}

.flex {
    display: flex;
}

.col-flex {
    display: flex;
    flex-direction: column;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.sm-show {
    display: none;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.align-start {
    align-items: flex-start;
}

.wrap {
    flex-wrap: wrap;
}

.container {
    padding: 20px 30px;
}

.box-gray {
    border-radius: 20px;
    border: 1px solid;
    background: #eaeaea;
}

.relative {
    position: relative;
}

.fit-content {
    width: fit-content;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.border {
    border: 1px solid;
}

.expanded-question-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 100;
    background: rgba(0,0,0,0.8);

    .dialog {
        position: absolute;
        inset: 0;
        max-width: 900px;
        width: 75%;
        height: 80%;
        background: white;
        border-radius: 12px;
        margin: auto;

        .header {
            background: #E9ECEF;
            padding: 16px 24px;
            color: black;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;

            .review-tag {
                .review-tag-icon {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                }

                .review-tag-title {
                    font-size: 18px;
                    font-weight: 500;
                    padding: 7px 15px 7px 40px;
                    background: #FFFFFF;
                    border-radius: 17px;
                    margin-left: -30px;
                }
            }

            .time-taken {
                border-radius: 2px;
                background: #64D2FF33;
                padding: 11px 12px;
                min-width: 95px;
                border-radius: 8px;
                margin-right: 16px;

                .time {
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 500;

                }
            }

            .close-icon {
                width: 42px;
                height: 42px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;
                cursor: pointer;
            }
        }

        .body {
            color: black;
            padding: 16px 24px;
            height: calc(100% - 80px);
            overflow: scroll;

            &::-webkit-scrollbar {
                width: 22px !important;
            }
            &::-webkit-scrollbar {
                width: 20px;
              }
              
              &::-webkit-scrollbar-track {
                background-color: transparent;
              }
              
              &::-webkit-scrollbar-thumb {
                background-color: #c7c7c7;
                border-radius: 20px;
                border: 6px solid transparent;
                background-clip: content-box;
              }
              
              &::-webkit-scrollbar-thumb:hover {
                background-color: #a0a0a0;
              }

            .question {
                font-size: 24px;
                font-weight: 600;
                max-width: calc(100% - 100px);
                text-align: left;
            }

            .single-and-multi-select {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 24px;
                margin-top: 24px;

                .option {
                    padding-left: 24px;
                    min-height: 48px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    background-color: #E9ECEF;
                    border-radius: 6px;
                    position: relative;

                    .icon {
                        min-width: 22px;
                        min-height: 22px;
                        margin-right: 20px;
                        background-size: cover;
                        background: url('../../assets/review-option.svg');
                        
                        &.correct {
                            background: url('../../assets/correct-review-option.svg');
                        }
                        
                        &.incorrect {
                            background: url('../../assets/incorrect-review-option.svg');
                        }
                    }

                    .your-ans {
                        background-color: #DEE2E6;
                        font-size: 12px;
                        font-weight: 500;
                        padding: 5px;
                        color: white;
                        border-radius: 2px 0px 0px 2px;

                        &.correct {
                            background-color: #1BB55C;
                        }

                        &.incorrect {
                            background-color: #DD234C;
                        }
                    }
                }
            }
            
            .options-rearrange {
                .your-answers {
                    margin-top: 20px;

                    .title {
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .options {
                        width: 100%;
                        margin-top: 20px;

                        .option {
                            position: relative;
                            width: 139px;
                            height: 212px;
                            border-radius: 12px;
                            text-align: left;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &.correct {
                                border: 8px solid #1BB55C;
                            }

                            &.incorrect {
                                border: 8px solid #DD234C;
                            }
                        }
                    }
                }
            }

            .options-drag-match {
                margin-top: 20px;

                .options {
                    margin-top: 20px;
                    width: 100%;
                }

                .title {
                    font-size: 18px;
                    font-weight: 600;
                }

                .buckets {
                    width: 49%;

                    .bucket {
                        height: 50px;
                        margin-bottom: 20px;
                        border-radius: 5px;
                        background: #E9ECEF;
                        display: flex;
                        align-items: center;
                        padding: 12px 10px;

                        .index {
                            width: 22px;
                            height: 22px;
                            border-radius: 50%;
                            background: white;
                            border: 1px solid #ADB5BD;
                            font-size: 12px;
                            font-weight: 600;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .value {
                            margin-left: 10px;
                        }
                    }
                }

                .user-options {
                    margin-left: 20px;
                    width: 49%;

                    .bucket {
                        height: 50px;
                        margin-bottom: 20px;
                        border-radius: 5px;
                        background: #E9ECEF;
                        display: flex;
                        align-items: center;
                        padding: 12px 10px;

                        .icon {
                            width: 22px;
                            height: 22px;
                            margin-right: 20px;
                            background-size: cover;
                            background: url('../../assets/review-option.svg');
                            
                            &.correct {
                                background: url('../../assets/correct-review-option.svg');
                            }
                            
                            &.incorrect {
                                background: url('../../assets/incorrect-review-option.svg');
                            }
                        }

                        .value {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .options-tap-type {
                font-weight: 500;
                font-size: 24px;
                margin-top: 40px;
                max-height: 250px;
                // overflow: scroll;
                text-align: left;

                .correct-ans {
                    background: #1BB55C;
                    border-radius: 12px;
                    color: white;
                    height: 38px;
                    display: inline-flex;
                    padding: 0 20px;
                    justify-content: center;
                    align-items: center;
                }

                .blank {
                    font-weight: 600;
                    padding: 3px 10px;
                    display: inline-flex;
                    
                    &.correct {
                        color: #1BB55C;
                        border-bottom: 2px solid #1BB55C;
                    }
                    
                    &.incorrect {
                        color: #C2163C;
                        text-decoration: line-through;
                        border-bottom: 3px solid #C2163C;
                    }
                }
            }

            // .options {
            //     margin-top: 40px;
            //     max-height: 250px;
            //     overflow: scroll;

            //     .option {
            //         .icon {
            //             min-width: 22px;
            //             min-height: 22px;
            //             margin-right: 20px;
            //             background-size: cover;
            //             background: url('../../assets/review-option.svg');
                        
            //             &.correct {
            //                 background: url('../../assets/correct-review-option.svg');
            //             }
                        
            //             &.incorrect {
            //                 background: url('../../assets/incorrect-review-option.svg');
            //             }
            //         }
                    
            //         height: 47px;
            //         font-size: 18px;
            //         font-weight: 600;
            //         text-align: left;
            //         background: #E9ECEF;
            //         border-radius: 4px;
            //         width: 47%;
            //         margin-right: 20px;
            //         margin-bottom: 30px;
            //         padding: 12px 0px 12px 10px;
            //     }
            // }

            .solution-container {
                margin-top: 24px;
                font-size: 20px;
                text-align: start;
                width: 100%;
                max-height: 300px;

                .solution-title {
                    font-weight: 600;
                    margin-bottom: 12px;
                    text-align: start;
                }

                .solution-text {
                    margin-top: 12px;
                    font-weight: 500;
                }

                img{
                    object-fit: contain;
                    max-width: 600px;
                    max-height: 300px;
                }
            }
        }

        .carousel-button {
            border: none;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background-color: #ADB5BD;
            cursor: pointer;

            &.left {
                position: absolute;
                top: calc(50% - 12px);
                left: -56px;
            }

            &.right {
                position: absolute;
                top: calc(50% - 12px);
                right: -56px;
            }
        }

        .carousel-button:disabled {
            cursor: unset;
        }
    }
}