.solution-img-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  position: relative;
  padding: 0.1vw;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='grey' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");

  &.selected {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='orange' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  &.error {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='red' stroke-width='2' stroke-dasharray='8%2c10' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  .media-box-container-image {
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
    width: 100%;

    .media-box-image {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .input-image-label {
    font-size: 1vw;
    font-weight: 500;
    text-decoration: underline;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }

  #input-image-solution {
    display: none;
  }

  .close-icon-div {
    position: absolute;
    top: -0.7vw;
    right: -0.7vw;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #e9ecef;
    cursor: pointer;

    .close-icon {
      width: 55%;
      color: #2e3a59;
    }
  }

  .text-box {
    width: 0;
    height: 0;
    position: absolute;
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #ccc;
      font-weight: 500;
      text-align: center;
    }
  }
  .or-text {
    color: #ccc;
    font-size: 1vw;
    font-weight: 600;
  }
  .paste-img-text {
    color: #ccc;
    font-size: 1vw;
    font-weight: 700;
  }
  .loader {
    align-self: center;
    color: black;
  }
  .upload-icon {
    width: 1.4vw;
    height: 1.4vw;
    margin-left: 0.5vw;
  }
  .input-row {
    align-items: center;
    margin-top: 0.5vw;
  }

  .text-image-label {
    font-size: 1vw;
    font-weight: 500;
    text-decoration: underline;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.5vw;
  }
}
