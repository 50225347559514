.button-parent-div {
  width: 100%;
  padding: 0.5vw;
  border-radius: 0.5vw;
  border: 1.5px dashed #dee2e6;

  &.selected {
    border-color: #F49F3F;
  }

  &.error {
    border-color: red;
  }

  .buttonbox-div {
    width: 100%;
    position: relative;
    background-color: #ff9f0a;
    padding: 1vw;
    border-radius: 10px;

    .buttonbox-button {
      width: 100%;
      border: none;
      color: white;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      background-color: transparent;

      &::placeholder {
        color: white;
      }

      &:focus {
        outline: none;
      }
    }

    .close-icon-div {
      position: absolute;
      top: -1.25vw;
      right: -1.25vw;
      width: 1.5vw;
      height: 1.5vw;
      border-radius: 50%;
      background-color: white;
      justify-content: center;
      align-items: center;
      border: 1px solid #E9ECEF;
      cursor: pointer;
  
      .close-icon {
        width: 52%;
        color: #2E3A59;
      }
    }
  }
}
