.delete-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.385);
    z-index: 10000;

    .delete-modal {
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 0.5vw;
        padding: 1vw;
        gap: 1vw;

        .delete-modal-title {
            font-size: 1.25vw;
            font-weight: 600;
        }

        .delete-modal-action {
            width: 100%;
            justify-content: flex-end;
            gap: 1vw;

            .action-btn {
                padding: 0.4vw 1.5vw;
                border-radius: 0.5vw;

                .MuiButton-label {
                    font-size: 1vw;
                }
            }
        }
    }
}
