.single-select {
    width: 100%;
    display: grid  !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.833vw;

    #summaryChevron {
        cursor: pointer;
        background-color: #40007beb;
        border-radius: 50%;
        position: fixed;
        width: 56px;
        height: 56px;
        margin: auto;
        bottom: 30px;
        z-index: 100;

        &.chevron-visible {
            visibility: visible;
        }

        &.chevron-hidden {
            visibility: hidden;
        }
    }

    .option {
        position: relative;
        height: 12.708vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.222vw;
        font-weight: 600;
        padding: 1.389vw 1.389vw 1.389vw 4vw; 
        word-break: break-word;
        background:  #EFB150;
        border-bottom: 0.347vw solid rgba(0,0,0,0.2);
        color: white;
        cursor: pointer;
        border-radius: 0.694vw;

        .index {
            position: absolute;
            width: 2.222vw;
            height: 2.222vw;
            border-radius: 50%;
            font-size: 1.150vw;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            left: 1vw;
            background: rgba(0,0,0,0.1);
        }

        .tick-mark {
            position: absolute;
            top: 0.347vw; 
            right: 0.694vw;
            width: 2vw;
            height: 2vw;
            
            svg {
                width: 2vw;
                height: 2vw;
            }
        }
    }
}
.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.align-start {
    align-items: flex-start;
}

.wrap {
    flex-wrap: wrap;
}

.container {
    padding: 20px 30px;
}

.box-gray {
    border-radius: 20px;
    border: 1px solid;
    background: #eaeaea;
}

.relative {
    position: relative;
}

.fit-content {
    width: fit-content;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.border {
    border: 1px solid;
}
