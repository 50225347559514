.button-box{
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.2vw;
    font-weight: 700;
    background-color: #FF9F0A;
    border-radius: 0.75vw;
    padding: 0.75vw;
    width: 12vw;
}