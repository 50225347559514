.text-component {
  align-items: center;
  cursor: move;
  gap: 0.5vw;

  img {
    width: 3.5vw;
  }

  .text-title {
    font-size: 0.9vw;
    font-weight: 600;
  }

  .text-subtitle {
    font-size: 0.8vw;
    font-weight: 500;
    color: #818282;
  }
}