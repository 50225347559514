.single-select-box {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 0.6vw;
  width: 100%;
  position: relative;
  border: 1.5px dashed #ccc;

  &.selected {
    border-color: #f49f3f;
  }

  &.error {
    border-color: red;
  }

  .options-container-text {
    display: grid;
    gap: 0.5vw;

    .single-select-option {
      display: flex;
      gap: 0.75vw;
      border: 1px solid #e5e5e5;
      border-radius: 0.6vw;
      padding: 0.75vw;
      align-items: center;
      position: relative;

      .close-icon-option {
        position: absolute;
        top: -0.8vw;
        right: -0.8vw;
        width: 1.5vw;
        height: 1.5vw;
        border-radius: 50%;
        background-color: white;
        justify-content: center;
        align-items: center;
        border: 1px solid #e9ecef;
        cursor: pointer;

        .delete-icon {
          width: 52%;
          color: #2e3a59;
        }
      }

      .option-div {
        background-color: #e9ecef;
        border: 0.25vw solid #fafafa;
        border-radius: 50%;
        color: white;
        padding: 0.4vw;
        line-height: 0.75;
        color: #adb5bd;
        font-size: 0.8vw;
        font-weight: 600;
      }

      .option-input {
        width: 100%;
        border: none;
        border-radius: 5px;
        background-color: transparent;
        font-weight: 500;
        resize: none;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #adb5bd;
          font-weight: 500;
        }
      }
    }
  }

  .options-container {
    gap: 0.5vw;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .single-select-option {
      display: flex;
      border: 1px solid #ced4da;
      border-radius: 0.8vw;
      padding: 0.4vw;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
      aspect-ratio: 1/1;

      .close-icon-option {
        position: absolute;
        top: 0.25vw;
        right: 0.25vw;
        width: 1.5vw;
        height: 1.5vw;
        border-radius: 50%;
        background-color: white;
        justify-content: center;
        align-items: center;
        border: 1.5px solid #e9ecef;
        cursor: pointer;

        .delete-icon {
          width: 40%;
          color: #2e3a59;
        }
      }

      .edit-option-icon {
        position: absolute;
        bottom: 0.25vw;
        right: 0.25vw;
        width: 2vw;
        height: 2vw;
        border-radius: 50%;
        background-color: white;
        justify-content: center;
        align-items: center;
        border: 1px solid #e9ecef;
        cursor: pointer;

        .edit-icon {
          width: 1.9vw;
          height: 1.9vw;
          color: #2e3a59;
        }
      }

      .option-div {
        background-color: #e9ecef;
        width: 1.75vw;
        height: 1.75vw;
        border-radius: 50%;
        color: #adb5bd;
        font-size: 0.8vw;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0.25vw;
        left: 0.25vw;
        border: 0.25vw solid #fafafa;
        z-index: 9;
      }

      .upload-img-option {
        display: flex;
        flex-direction: column;
        padding: 0.5vw 0 0.5vw 0;
        gap: 0.25vh;

        .option-image-icon {
          width: 2.5vw;
          height: 2vw;
        }

        .label-text {
          color: #adb5bd;
          font-weight: 500;
          font-size: 0.6vw;
          text-align: center;
        }
      }

      .option-input {
        width: 85%;
        border: none;
        border-radius: 5px;
        background-color: transparent;
        font-weight: 500;
        text-align: center !important;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #adb5bd;
          font-weight: 500;
          font-size: 0.8vw;
          text-align: center;
        }
      }

      .option-input-paste {
        width: 0;
        height: 0;
        position: absolute;
        border: none;
        background-color: transparent;

        &:focus {
          outline: none;
        }
      }
    }

    #option-input-image {
      display: none;
    }

    .image-uploaded-div {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .option-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 0.6vw;
      }

      .edit-icon {
        width: 1vw;
        height: 1vw;
      }
    }
  }

  .correct-answer-div {
    gap: 0.5vw;

    .correct-answer-title {
      font-size: 1vw;
      font-weight: 500;
    }

    .correct-answer-option-box {
      gap: 0.5vw;

      .correct-answer-option {
        padding: 0.5vw;
        line-height: 0.75;
        background-color: #adb5bd;
        border-radius: 50%;
        font-size: 0.75vw;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #fff;
      }
    }
  }

  .solution-container {
    gap: 0.25vw;

    .solution-title {
      font-size: 1.2vw;
      font-weight: 700;
    }

    .solution-box {
      border: 1.5px solid #ccc;
      border-radius: 5px;
      gap: 0.5vw;
      padding: 0.5vw;

      .solution-box-input {
        padding: 0;
        width: 100%;
        border: none;
        background-color: transparent;
        resize: none;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #adb5bd;
          font-weight: 500;
        }
      }

      .solution-box-action {
        font-size: 1vw;
        font-weight: 500;
        text-decoration: underline;
        gap: 5px;
        align-items: center;
        cursor: pointer;
      }
    }

    .upload-icon {
      font-size: 1.5vw;
    }
  }

  .close-icon-div {
    position: absolute;
    top: -0.8vw;
    right: -0.8vw;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #e9ecef;
    cursor: pointer;

    .close-icon {
      width: 52%;
      color: #2e3a59;
    }
  }

  .radio-div {
    .option-type-title {
      color: #343a40;
      font-size: 0.9vw;
      font-weight: 600;
    }

    .MuiFormControlLabel-label {
      font-size: 0.9vw !important;
      font-weight: 500;
    }

    .MuiFormGroup-root {
      flex-wrap: nowrap;
    }

    .PrivateSwitchBase-root-18 {
      padding: 5px;
    }

    .MuiFormControlLabel-root {
      margin-left: -5px;
    }
  }
}
