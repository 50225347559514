.flash-cards {
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  height: 100%;

  * {
    font-family: 'Montserrat', sans-serif !important;
  }

  .flash-cards-left {
    gap: 2vw;
    height: 100%;
    width: 50%;

    .back-navigation {
      width: max-content;
      cursor: pointer;
      gap: 1vw;
      font-size: 1vw;
      color: #6c757d;
      font-weight: 600;

      &:hover {
        color: #0d0d0d;

        svg {
          path {
            fill: #0d0d0d;
          }
        }
      }

      svg {
        width: 1vw;

        path {
          fill: #6c757d;
        }
      }
    }

    .draggable-elements {
      gap: 1vw;
      align-items: flex-start;
      height: 100%;
      width: 100%;
      overflow: auto;

      .elements-title {
        font-size: 1.5vw;
        font-weight: 600;
      }

      .media-section {
        gap: 1vw;
      }

      .text-section {
        gap: 1vw;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .flash-cards-right {
    width: 50%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 4vw;

    .flash-cards-ui {
      height: 100%;
      gap: 1vw;
      width: 23vw;
      align-items: center;

      .slides-stepper {
        gap: 0.5vw;
        align-items: center;

        .stepper {
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          height: 1.3vw;
          width: 1.3vw;
          font-size: 0.9vw;
          font-weight: 700;
          cursor: pointer;

          &.non-current {
            color: white;
            background-color: #8e8e8e;

            &:hover {
              background-color: #7c7c7c;
            }
          }

          &.current {
            color: white;
            background-color: orange;

            &:hover {
              background-color: rgb(238, 155, 0);
            }
          }

          &.add-slide-btn {
            border: 0.5px solid black;

            &:hover {
              background-color: rgb(236, 236, 236);
            }

            svg {
              font-size: 1vw;
            }
          }
        }
      }
    }

    .flash-cards-action {
      height: 100%;
      gap: 1vw;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 20%;

      .status {
        position: absolute;
        top: 0;
        margin: auto;
        border-radius: 0.35vw;
        white-space: nowrap;
        color: #dd234c;
        font-size: 1.25vw;
        font-weight: 600;
        align-items: center;
        gap: 0.5vw;

        &.live {
          color: #1bb55c;
        }

        svg {
          width: 0.75vw;
          height: 0.75vw;

          path {
            fill: #1bb55c;
          }
        }
      }

      .btn {
        width: 100%;
        padding: 0.75vw 0;
        border: none;
        border-radius: 0.6vw;
        color: white;
        font-weight: 600;
        font-size: 1vw;
        cursor: pointer;
        background-color: transparent;

        &:disabled {
          background-color: #ccc;
          cursor: unset;
        }

        div {
          display: block;
          color: #bf5af2;
          margin: auto;
        }
      }

      .save-btn {
        background-color: #bf5af2;

        &:hover {
          background-color: #b641f1;
        }
      }

      .publish-btn {
        border: 1.5px solid #bf5af2;
        color: #bf5af2;

        &:hover {
          background-color: #bf5af21c;
        }
      }

      .preview-box {
        position: absolute;
        top: 3vw;
        gap: 0.5vw;

        .preview-btn {
          background: linear-gradient(89.76deg, #f2863c 0.17%, #f9b645 88.95%);

          &:hover {
            background: linear-gradient(
              89.76deg,
              #f27e30 0.17%,
              #f7b035 88.95%
            );
          }

          svg {
            vertical-align: middle;
            margin: 0 0.5vw 0.15vw 0;
            width: 1vw;
            height: 1vw;
          }
        }

        p {
          color: #6c757d;
          font-size: 0.75vw;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }

  .slides-actions-box {
    gap: 1vw;

    .btn {
      border: none;
      padding: 0.75vw 1vw;
      align-items: center;
      cursor: pointer;
      background-color: transparent;
      border-radius: 0.5vw;

      .icon {
        vertical-align: middle;
        width: 1vw;
        margin-right: 0.25vw;
        margin-bottom: 0.15vw;
      }

      &:enabled {
        &:hover {
          background-color: #f0f0f0;
        }
      }

      &:disabled {
        cursor: unset;
        color: unset;
        opacity: 0.3;
      }
    }

    .delete-btn {
      font-size: 1vw;
      font-weight: 500;
    }

    .dublicate-btn {
      font-size: 1vw;
      font-weight: 500;
    }
  }
}
