.activity-space {
    width: 100%;
    padding: 0 1.389vw;
    background: #10002b;
  
    .activity-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .countdown-container {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0px;
      left: 0px;
      z-index: 1000;
    }
  
    .close-btn {
      position: absolute;
      top: 2vw;
      right: 2vw;
      cursor: pointer;
      z-index: 1001;
      width: 2vw;
      height: 2vw;
      background: white;
      box-shadow: 4px 2px 50px rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
  
      svg {
        width: 0.8vw;
        height: 0.8vw;
      }
    }
  
    .video-player-overlay {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1000;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
  
      video {
        width: 70%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border: 3px solid #bf5af2;
        border-radius: 10px;
      }
    }
  
    .zoomed-image {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #000000b5;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      cursor: zoom-out;
    }
  
    .activity-header {
      width: 100%;
      height: 5.5vw;
      padding: 1.042vw 0;
      justify-content: flex-end;
  
      .tech-support {
        color: white;
        font-size: 0.833vw;
        font-weight: 700;
        background: #3c096c;
        border-radius: 0.417vw;
        padding: 0px 24px;
        width: max-content;
        height: 100%;
        margin-right: 1.042vw;
        cursor: pointer;
        &:hover {
          background: #5a189a;
          svg {
            fill: #ffffff;
          }
        }
      }
  
      .stats {
        font-size: 1.111vw;
        font-weight: 500;
        color: white;
        border: 0.139vw solid #6c757d;
        border-radius: 0.694vw;
        padding: 0.694vw 1.389vw;
        height: 100%;
  
        svg {
          margin-right: 0.694vw;
        }
      }
  
      .full-screen {
        background: #240046;
        border-radius: 0.972vw;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.611vw;
        height: 100%;
        margin-left: 0.694vw;
        cursor: pointer;
  
        svg {
          width: 2.222vw;
          height: 2.222vw;
        }
      }
    }
  
    .activity {
      flex: 1;
      position: relative;
      width: 100%;
      height: 80vh;
      border-radius: 1.389vw;
      color: white;
      overflow: hidden;
      background: #240046;
      padding: 3.472vw;
  
      .chevron {
        cursor: pointer;
        z-index: 100;
        position: absolute;
        bottom: 3%;
  
        .scroll-chevron {
          width: 3vw;
          height: 3vw;
          background-color: #bf5af2;
          border-radius: 50%;
          border: 4px solid #240046;
  
          svg {
            width: 1vw;
            height: 1vw;
          }
        }
  
        .scroll-text {
          font-size: 0.8vw;
          white-space: nowrap;
        }
  
        &.options-chevron {
          right: 29.9vw;
        }
  
        &.question-chevron {
          left: 16vw;
        }
  
        &.center-chevron {
          left: 0;
          right: 0;
          margin: auto;
        }
  
        &.visible {
          display: flex;
        }
  
        &.hidden {
          display: none;
        }
      }
  
      .loader-container {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 0.694vw;
        overflow: hidden;
  
        .loader-bar {
          background: #5a189a;
          width: 100%;
          height: 100%;
          border-radius: 0.208vw;
          animation-name: loader;
          animation-timing-function: linear;
        }
  
        @keyframes loader {
          to {
            width: 0px;
          }
        }
      }
  
      .question-index {
        position: absolute;
        top: 0.694vw;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
  
        svg {
          width: 4.306vw;
          height: 3.681vw;
        }
  
        .value {
          position: absolute;
          z-index: 100;
          font-size: 0.972vw;
          font-weight: 500;
          display: flex;
          align-items: flex-end;
  
          .big {
            font-size: 1.667vw;
          }
  
          .small {
            font-size: 0.972vw;
          }
        }
      }
  
      .tap-type-question {
        width: 100%;
        height: 80%;
        padding: 0px 2.083vw 2.083vw 2.083vw;
        font-size: 1.944vw;
        overflow-y: auto;
  
        &.center {
          align-items: center;
        }
  
        &.start {
          align-items: flex-start;
        }
      }
  
      .rearrange-question {
        width: 100%;
        max-height: 100%;
        font-size: 1.806vw;
        overflow: auto;
  
        &.center {
          align-items: center;
        }
      }
  
      .question-part {
        position: relative;
        font-size: 2.222vw;
        font-weight: 500;
        padding-right: 1.7vw;
        margin-right: 1.7vw;
        max-height: 100%;
        width: 35%;
        overflow-y: auto;
  
        audio {
          width: 100%;
          margin-bottom: 16px;
        }

        .video-placeholder {
            width: 100%;
            height: 200px;
            margin-bottom: 16px;
            background: black;
            border: 2px solid #b25fea;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          
            .play-btn {
              width: 60px;
              height: 60px;
              background: white;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
            }
          }
  
        &.center {
          justify-content: center;
        }
  
        &.start {
          justify-content: flex-start;
        }
  
        &::-webkit-scrollbar {
          width: 0.25vw !important;
        }
  
        &::-webkit-scrollbar-track {
          background: none;
        }
  
        &::-webkit-scrollbar-thumb {
          background: #bf5af2;
        }
      }
  
      .options-part {
        max-height: 100%;
        width: 65%;
        overflow: auto;
        scrollbar-width: 1vw !important;
        scrollbar-gutter: 1vw !important;
  
        &.center {
          align-items: center;
        }
  
        &.start {
          align-items: flex-start;
        }
      }
    }
  
    .actions {
      width: 100%;
      height: 5.5vw;
      padding: 1.042vw 0;
      position: relative;
  
      .controls {
        height: 100%;
        margin-right: 16px;
  
        .button {
          width: 4vw;
          height: 100%;
          margin-right: 1.042vw;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.694vw;
          cursor: pointer;
          background: #240046;
  
          .sound-button-muted {
            background: url('./svg/music-icon-muted.svg');
            background-size: cover;
            width: 2vw;
            height: 2vw;
          }
  
          .sound-button {
            background: url('./svg/music-icon.svg');
            background-size: cover;
            width: 2vw;
            height: 2vw;
          }
        }
  
        .button-empty {
          background: none;
        }
      }
  
      .action-cta {
        position: absolute;
        left: 50%;
        margin: 0 auto 0 -33.368vw;
        width: 66.736vw;
        height: calc(100% - 2.084vw);
        font-size: 1.544vw;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        border-radius: 0.694vw;
        cursor: pointer;
        border-bottom: 0.347vw solid rgb(147, 66, 202);
        background: rgba(178, 95, 234, 1);
  
        &.correct {
          background: #28963c;
          border-color: #28963c;
        }
  
        &.incorrect {
          background: #dd234c;
          border-color: #dd234c;
        }
  
        .action-cta-loader {
          position: absolute;
          background: white;
          left: 0px;
          top: 0px;
          height: 108%;
          opacity: 0.5;
          border-radius: 0.694vw;
          border-bottom: 0.278vw solid rgb(147, 66, 202);
        }
      }
  
      .nav-buttons {
        z-index: 101;
        height: 100%;
  
        &.hide {
          display: none;
        }
  
        .button {
          margin-left: 0.694vw;
          border: none;
          border-radius: 0.694vw;
          cursor: pointer;
          background: #240046;
          min-width: 4vw;
          height: 100%;
          justify-content: center;
  
          .image {
            width: 2vw;
            height: 2vw;
          }
  
          .animate-hand {
            @keyframes teacherReply {
              0% {
                transform: scale(1);
              }
              100% {
                transform: scale(1.5);
              }
            }
            animation: teacherReply 500ms linear alternate infinite;
          }
        }
      }
    }
  }
  