.multiple-select {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.833vw;

    .option {
        position: relative;
        height: 12.708vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.222vw;
        font-weight: 600;
        padding: 1.389vw 1.389vw 1.389vw 4vw; 
        word-break: break-word;
        background: rgb(230,179,97);
        border-bottom: 0.347vw solid rgba(0,0,0,0.2);
        color: white;
        cursor: pointer;
        border-radius: 0.694vw;

        .index {
            position: absolute;
            width: 2.222vw;
            height: 2.222vw;
            border-radius: 50%;
            font-size: 1.150vw;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            left: 1vw;
            background: rgba(0,0,0,0.1);
        }

        .tick-mark {
            position: absolute;
            top: 0.347vw; 
            right: 0.694vw;
            width: 2vw;
            height: 2vw;
            
            svg {
                width: 2vw;
                height: 2vw;
            }
        }
    }
}