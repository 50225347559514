.TeacherTrainerContainer {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  min-height: 70vh;
  .TeacherTrainerHeaderContainer {
    display: flex;
    justify-content: space-between;
    .TeacherTrainerHeader {
      font-size: 1.5vw;
      font-weight: 600;
    }
  }
  .tableContainer {
    overflow-x: auto;
    .MuiTableHead-root {
      background-color: #e0e0e0;
      font-size: 1vw;
      font-weight: 600;
      height: 4.5vw;
      position: sticky;
      z-index: 1;
      top: 0;
    }
    .MuiTableCell-root {
      text-align: center;
      padding: 6px 24px 6px 16px;
      height: inherit;
      font-size: inherit;
      font-weight: inherit;
      &.MuiTableCell-head {
        &.MuiTableCell-stickyHeader {
          background-color: #e0e0e0 !important;
        }
      }
    }
    .tableCellFixed {
      position: sticky;
      z-index: 2;
      min-width: 6vw;
      max-width: 6vw;
      background-color: #ffffff;
      top: 100px;
    }
    .tableHeaderFixed {
      z-index: 3;
      top: 0;
    }
    .bold {
      font-weight: 600;
      font-size: 0.95vw;
      background-color: #f1f0f0;
    }
    .MuiTableBody-root {
      font-size: 1vw;
      font-weight: 500;
      height: 4vw;
    }

    .MuiCircularProgress-root {
      margin: auto;
    }
  }
  .MuiTablePagination-root:last-child {
    width: fit-content;
  }
  .centerAligned {
    margin: auto;
  }
  .clickable {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
  .MuiSelect-selectMenu {
    white-space: normal;
  }
}
.headers {
  display: flex;
  align-items: center;
  gap: 0.75vw;
  .sortIcon {
    font-size: 1.5vw;
    cursor: pointer;
  }
}
